import * as React from 'react'
import { useRouter } from 'next/router'

import { createPage } from '@app/nextjs'

import { useAuth } from '@saas-ui/auth'
import { AuthPage } from '@app/features/core/layouts/app-layout'

const Login = () => {
  const { isAuthenticated } = useAuth()
  const router = useRouter()

  React.useEffect(() => {
    if (isAuthenticated) {
      router.replace('/')
    }
  }, [isAuthenticated, router])

  return <AuthPage title={'Log in to Schemamap.io'} action="logging in" />
}

export default createPage({
  title: 'Login',
  layout: 'auth',
  isPublic: true,
  renderComponent: () => <Login />,
})
